






































































































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { Message } from "element-ui";

@Component({})
export default class AUser extends Vue {
  // 表格数据
  tableData: any = [];
  tableDataArea: any = []
  tableDataJifen: any = []
  jifenConfig = {}
  couponSwitch = {
    0: '关闭',
    1: '开启'
  }
  // 加载状态
  loading = false;
  sureLoad = false
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  integralScanType = {
    0: '数值',
    1: '比例'
  }
  config = {
    0: '待使用',
    1: '已使用',
    2: '使用过'
  }

  radioId = ''
  rowClick(row: any) {
    this.radioId = row.id
  }
  cloneloading = false
  cloneRuleInfo() {
    if(this.radioId == '') {
      this.$message.warning('请选择数据')
      return
    }
    this.cloneloading = true
    api.post('J27601', {id: this.radioId}).then(res => {
      this.$message.success('操作成功')
      api.post('J27104', {areaCode: this.getAreaCode}).then(res => {
        this.tableDataJifen = Object.values(res)[0]
      })
    }).finally(() => {
      this.cloneloading = false
    })
  }

  // 积分总开关
  setCouponswitch(row: any, item: any) {
    // api.post('J27328', {areaCode: item.areaCode, couponSwitch: row})
    api.post('J27328', {id: row.id, couponSwitch: item})
      .then(res => {
        this.$message.success('操作成功')
        this.searchTable()
      })
  }

  // 查看清单
  qingdanDialog = false
  tableQingdan = []
  toQingdan(row: any) {
    console.log(row);
    api.post('J27430', {areaCode: row.areaCode}).then(res => {
      this.qingdanDialog = true
      this.tableQingdan = res.list
    })


  }
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J27101", this.search)
      .then((data) => {
        let areaCode: any = []  ;
        data.list.forEach((item: any, index: any) => {
          areaCode.push(item.areaCode)
        })
        this.total = 0;
        this.tableData = [];
        if(data.list && data.list.length != 0) {
          this.getIntegralRuleList(areaCode, data)
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 同步
  syncloading = false
  syncAreaRule() {
    this.syncloading = true
    api
      .post('J27102')
      .then(res => {
        this.$message.success('操作成功')
        this.searchTable()
      }).finally(() => {
      this.syncloading = false
    })
  }

  //积分规则列表
  getIntegralRuleList(areaCode: any, listData: any) {
    api
      .post("J27104", {areaCode})
      .then((data) => {
        Object.keys(data).forEach((item: any, index: any) => {
          listData.list.forEach((itx: any, key: any) => {

            if(item == itx.areaCode) {
              itx.integral = data[item]
              itx.status = itx.status
            }
          })
        })
        this.total = listData.total;
        this.tableData = listData.list;

      });
  }

  //列表状态查询
  getStatusEnum() {
    api
        .post("J27110", {keyword: '5'})
        .then((data) => {
          this.jifenConfig = data
        });
  }

  //查看
  detailDialog = false
  detail(row: any) {
    this.tableDataJifen = JSON.parse(JSON.stringify(row.integral))
    this.detailDialog = true
  }

  // 扫码得积分
  getJifenDialog = false
  getAreaCode = []
  getJifen(row: any) {
    this.radioId = ''
    this.getAreaCode = row.areaCode.split()
    api.post('J27104', {areaCode: this.getAreaCode}).then(res => {
      // this.tableDataJifen = JSON.parse(JSON.stringify(row.integral))
      console.log('13121321', Object.values(res));
      this.tableDataJifen = Object.values(res)[0]
      this.getJifenDialog = true
    })

  }
  // 扫码送积分
  setScanIntegral(row: any) {
    this.sureLoad = true
    api
      .post("J27603", {list: row})
      .then((data) => {
        this.searchTable()
        this.getJifenDialog = false
      }).finally(() =>{
        this.sureLoad = false
      });
  }
  sureSetScan(){
    let scanIntegralDtos: any = []
    this.tableDataJifen.forEach((row: any) => {
      scanIntegralDtos.push({
        id: row.id,
        integralScanType: row.integralScanType,
        integralNumberScan: row.integralNumberScan,
        effectTimeStart: row.effectTime ? row.effectTime[0] : '1970-01-02 00:00:00',
        effectTimeEnd: row.effectTime ? row.effectTime[1] : '1970-01-02 00:00:00'

      })
    })
    this.setScanIntegral(scanIntegralDtos)
  }

  // 注册送积分
  integralNumberRegister = ''
  saveloading = false
  setRegisterIntegral(){
    this.saveloading = true
    api
        .post("J27107", {integralNumberRegister: this.integralNumberRegister})
        .then((data) => {
          this.$message.success('操作成功')
          this.searchTable()
        }).finally(() => {
          this.saveloading = false
    });
  }
  getIntegral() {
    api.post('J27602').then(res => {
      this.integralNumberRegister = res
    })

  }


  // 设置积分上限  setJifenDialog
  setJifenDialog = false
  setJifen(row: any) {
    this.tableDataArea = JSON.parse(JSON.stringify(row.integral))
    this.setJifenDialog = true
  }
  setIntegralLimit(row: any) {
    this.sureLoad = true
    api
      .post("J27108", {list: row})
      .then((data) => {
        this.searchTable()
        this.setJifenDialog = false
      }).finally(() =>{
      this.sureLoad = false
    });
  }
  sure() {
    let integralLimitDtos: any = []
    this.tableDataArea.forEach((row: any) => {
      console.log('参数', row);
      integralLimitDtos.push({
        id: row.id,
        integralUpperLimit: row.integralUpperLimit,
      })
    })
    this.setIntegralLimit(integralLimitDtos)
  }



  // 修改营销规则
  updateMarketRule(rows: any, item: any) {
    let obj = {
      id: rows.id,
      status: item,
    }
    api
        .post("J27103", obj)
        .then((data) => {
          this.$message.success('操作成功')
          this.searchTable()
        });
  }


  activated() {
    this.getStatusEnum()
    this.searchTable();
    this.getIntegral()
  }
}
